import { writable } from 'svelte/store';

export const outputMode = writable("story"); // Initial mode
export const outputContent = writable(""); // Output content

export const detectionResultsStore = writable({ face: [], body: [], hand: [], emotions: [], gesture: [], object: [] });




