<script>
    import { onMount } from "svelte";
    import Human from "@vladmandic/human";
    import { detectionResultsStore } from "../stores/store.js";

    let videoElement;
    let human;

    const initializeHuman = async () => {
        human = new Human({
            modelBasePath: "https://vladmandic.github.io/human/models/",
            debug: true,
            filter: { enabled: true }, // Enable filters for better performance
            face: { enabled: true, emotion: { enabled: true } }, // Enable face and emotion detection
        });

        console.log("Initializing Human.js...");

        try {
            await human.load();
            await human.warmup();
            console.log("Human.js initialized");
            startVideoStream();
        } catch (err) {
            console.error("Error during Human.js initialization:", err);
        }
    };

    const startVideoStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({video: true});
            videoElement.srcObject = stream;

            await videoElement.play();
            console.log("Video stream started");
            detect();
        } catch (err) {
            console.error("Error accessing webcam:", err);
        }
    };

    const detect = async () => {
        if (human && videoElement) {
            const result = await human.detect(videoElement);
            detectionResultsStore.set(result); // Update the entire result object

            requestAnimationFrame(detect);
        }
    };

    onMount(() => {
        initializeHuman();
    });
</script>

<style>
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
    }

    video {
        border: 1px solid #ccc;
        border-radius: 8px;
        width: 640px;
        height: 480px;
        background: #000;
    }
</style>

<div class="container">
    <video bind:this={videoElement} autoplay muted playsinline></video>
</div>
