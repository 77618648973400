<script>
    import { onMount } from "svelte";
    import * as Tone from "tone";

    export let imageData;

    let synth, piano, guitar, gong;
    let instruments = {};

    // Initialize instruments
    onMount(() => {
        synth = new Tone.Synth().toDestination();

        piano = new Tone.Sampler({
            urls: { A4: "A4.mp3", C4: "C4.mp3" },
            baseUrl: "https://tonejs.github.io/audio/salamander/",
        }).toDestination();

        gong = new Tone.Player(
            "https://tonejs.github.io/audio/berklee/gong_1.mp3"
        ).toDestination();
        Tone.loaded().then(() => {
            gong.start();
        });

        guitar = new Tone.Synth({ oscillator: { type: "triangle" } }).toDestination();

        instruments = { red: piano, green: gong, blue: guitar };
    });

    $: if (imageData) {
        processCanvasData(imageData);
    }

    function processCanvasData(imageData) {
        const { data, width, height } = imageData;

        // Define grid dimensions
        const gridSize = 10; // Divide canvas into 10x10 grid
        const cellWidth = Math.floor(width / gridSize);
        const cellHeight = Math.floor(height / gridSize);

        const melody = [];
        const notes = [
            "C4", "D4", "E4", "F4", "G4", "A4", "B4",
            "C5", "D5", "E5", "F5", "G5", "A5", "B5",
        ];

        // Iterate through the grid
        for (let gridY = 0; gridY < gridSize; gridY++) {
            for (let gridX = 0; gridX < gridSize; gridX++) {
                let totalBrightness = 0;
                let pixelCount = 0;

                // Calculate aggregated brightness for the cell
                for (let y = gridY * cellHeight; y < (gridY + 1) * cellHeight; y++) {
                    for (let x = gridX * cellWidth; x < (gridX + 1) * cellWidth; x++) {
                        const index = (y * width + x) * 4; // Pixel index in `data`
                        const [r, g, b] = [data[index], data[index + 1], data[index + 2]];
                        const brightness = (r + g + b) / 3;
                        totalBrightness += brightness;
                        pixelCount++;
                    }
                }

                const avgBrightness = totalBrightness / pixelCount;

                // Map brightness to a note
                const noteIndex = Math.floor((avgBrightness / 255) * notes.length);
                const note = notes[noteIndex] || "C4";
                melody.push(note);
            }
        }

        console.log("Generated Melody (Grid-Based):", melody);

        // Play the melody with the default instrument (or add color logic here)
        playMelody(melody, piano);
    }

    async function playMelody(melody, instrument) {
        const now = Tone.now();
        melody.forEach((note, index) => {
            instrument.triggerAttackRelease(note, "8n", now + index * 0.3);
        });
    }
</script>

<p>Grid-Based Sound Output</p>
