<script>
    import { onMount } from "svelte";
    import { poseStore } from "../stores/poseStore.js";
    import * as Tone from "tone";

    let leftWristSynth, rightWristSynth, drumPatterns;
    let currentPattern = null;

    // Initialize two separate synthesizers
    onMount(() => {
        /*
        leftWristSynth = new Tone.Synth({
            oscillator: { type: "sine" }, // Smooth sine wave for the left wrist
            envelope: { attack: 0.1, decay: 0.2, sustain: 0.7, release: 1.0 }
        }).toDestination();
        */
        leftWristSynth = new Tone.MembraneSynth({
            pitchDecay: 0.05,
            octaves: 4,
            oscillator: { type: "sine" },
            envelope: { attack: 0.01, decay: 0.3, sustain: 0.0, release: 1.0 }
        }).toDestination();

        rightWristSynth = new Tone.Synth({
            oscillator: { type: "square" }, // Hollow square wave for the right wrist
            envelope: { attack: 0.05, decay: 0.1, sustain: 0.8, release: 0.8 }
        }).toDestination();

        drumPatterns = [
            new Tone.Pattern((time) => leftWristSynth.triggerAttackRelease("C1", "8n", time), ["C1", null, "C1", null]),
            new Tone.Pattern((time) => leftWristSynth.triggerAttackRelease("C2", "8n", time), ["C2", "C2", null, "C2"]),
            new Tone.Pattern((time) => leftWristSynth.triggerAttackRelease("C3", "8n", time), ["C3", null, null, "C3"])
        ];

        Tone.Transport.start();
    });

    // Watch for changes in poses
    poseStore.subscribe((poses) => {
        if (poses.length > 0) {
            const leftWrist = poses[0].keypoints.find(kp => kp.name === "left_wrist");
            const rightWrist = poses[0].keypoints.find(kp => kp.name === "right_wrist");

            if (leftWrist && leftWrist.score > 0.5) {
                updateDrumPattern(leftWrist.y);
            }

            if (rightWrist && rightWrist.score > 0.5) {
                playMelody(rightWrist.y);
            }
        }
    });

    function updateDrumPattern(height) {
        const canvasHeight = 600; // Example canvas height
        const normalizedHeight = 1 - height / canvasHeight; // Normalize height (0-1)

        // Map height to pattern index
        const patternIndex = Math.floor(normalizedHeight * drumPatterns.length);

        // Clamp the pattern index to valid range
        const clampedIndex = Math.min(Math.max(patternIndex, 0), drumPatterns.length - 1);

        console.log(`Arm height: ${height}, Normalized: ${normalizedHeight}, Selected Pattern Index: ${clampedIndex}`);

        // Stop the current pattern if active
        if (currentPattern) currentPattern.stop();

        // Start the new pattern
        currentPattern = drumPatterns[clampedIndex];
        currentPattern.start(0); // Start immediately
    }

    function playMelody(height) {
        const canvasHeight = 600; // Example canvas height
        const normalizedHeight = 1 - height / canvasHeight; // Normalize height (0-1)
        const notes = ["C3", "D3", "E3", "F3", "G3", "A3", "B3", "C4"];
        const noteIndex = Math.floor(normalizedHeight * (notes.length - 1));
        const note = notes[noteIndex] || "C4";

        // Play the note with the specific synthesizer
        rightWristSynth.triggerAttackRelease(note, "8n");
    }
</script>

<p>Listening for Left and Right Wrist Movements...</p>
