<script>
    import Header from './components/Header.svelte';
    import { Router, Route, Link } from "svelte-routing";

    import HumanInput from './inputModules/HumanInput.svelte';
    import DrawInput from './inputModules/DrawInput.svelte';
    import MoveNetInput from './inputModules/MoveNetInput.svelte';

    import StoryOutput from './outputModules/StoryOutput.svelte';
    import HumanOutput from './outputModules/HumanOutput.svelte';
    import SpeechOutput from './outputModules/SpeechOutput.svelte';
    import SoundOutput from "./outputModules/SoundOutput.svelte";
    import Pose2ToneOutput from './outputModules/Pose2ToneOutput.svelte';


    import { outputMode } from './stores/store.js';

    let currentMode;
    $: currentMode = $outputMode;

    let imageData = null;
    function handleCanvasUpdate(data) {
        imageData = data;
    }

    let poses = [];
    function handleMoveNetCanvasUpdate(data) {
        poses = data;
    }
</script>

<Router>
    <Header title="FanRow" />

    <!-- Navigation Buttons -->
    <main>
        <div class="flex space-x-4 justify-center my-4">
            <button on:click={() => outputMode.set('story')} class="btn">Story</button>
            <button on:click={() => outputMode.set('move')} class="btn">Move</button>
            <button on:click={() => outputMode.set('human')} class="btn">Human</button>
            <button on:click={() => outputMode.set('draw')} class="btn">Draw</button>
        </div>

        <!-- Render Modules Conditionally -->
        <section class="container">
            {#if currentMode === 'story'}
                <div class="story">
                    <StoryOutput />
                </div>
            {/if}

            {#if currentMode === 'move'}
                <div class="input">
                    <MoveNetInput />
                </div>
                <div class="output">
                    <Pose2ToneOutput />
                </div>
            {/if}

            {#if currentMode === 'human'}
                <div class="input">
                    <HumanInput />
                </div>
                <div class="output">
                    <HumanOutput />
                    <!--<SpeechOutput />-->
                </div>
            {/if}

            {#if currentMode === 'draw'}
                <div class="input">
                    <DrawInput onCanvasUpdate={handleCanvasUpdate} />
                </div>
                <div class="output">
                    <SoundOutput {imageData} />
                </div>
            {/if}

        </section>
    </main>
</Router>

<style>
    main {
        padding: 20px;
    }

    .btn {
        background-color: #282c34;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        border-radius: 5px;
    }

    .btn:hover {
        background-color: #3b3f47;
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
    }

    @media (min-width: 768px) {
        .container {
            flex-direction: row;
        }
    }

    .input {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 1rem;
    }

    .output {
        flex: 1;
        /*display: flex;*/
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 1rem;
    }
</style>