<script>
    import { detectionResultsStore } from "../stores/store.js";

    let detectionResults = {};
    detectionResultsStore.subscribe((results) => {
        detectionResults = results || {}; // Fallback to an empty object if results are undefined
    });
</script>

<style>
    .results {
        margin-top: 1rem;
        font-size: 1rem;
        color: #333;
    }
</style>

<div class="results">
    {#if detectionResults.face && detectionResults.face.length > 0}
        <p>Detected emotions:</p>
        <pre>{JSON.stringify(detectionResults.face[0].emotion, null, 2)}</pre>
    {:else}
        <p>No emotions detected yet...</p>
    {/if}

    {#if detectionResults.body && detectionResults.body.length > 0}
        <p>Detected poses:</p>
        <pre>{JSON.stringify(detectionResults.body, null, 2)}</pre>
    {:else}
        <p>No poses detected yet...</p>
    {/if}
</div>
