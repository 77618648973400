<script>
    import { onMount, onDestroy } from "svelte";
    import { poseStore } from "../stores/poseStore.js";

    let canvasElement;
    let videoElement;
    let moveNetWorker;

    let isProcessing = false; // Prevent overlapping processing requests
    const frameSkip = 2; // Process every 3rd frame
    let frameCounter = 0;

    // Start the MoveNet worker
    onMount(() => {
        moveNetWorker = new Worker('./movenetWorker.js');

        moveNetWorker.onmessage = (e) => {
            poseStore.set(e.data); // Update the shared store with detected poses
            isProcessing = false; // Allow processing of the next frame
        };

        startCamera();
    });

    // Stop the worker when the component is destroyed
    onDestroy(() => {
        moveNetWorker.terminate();
    });

    async function startCamera() {
        videoElement = document.querySelector('#videoElement');
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoElement.srcObject = stream;

        videoElement.onloadedmetadata = () => {
            videoElement.play();
            processVideo();
        };
    }

    function processVideo() {
        console.log("..........");
        const ctx = canvasElement.getContext("2d", { willReadFrequently: true });
        const width = videoElement.videoWidth;
        const height = videoElement.videoHeight;

        canvasElement.width = width;
        canvasElement.height = height;

        function drawSkeleton(poses, ctx) {
            const keypointConnections = [
                [0, 1], [1, 3], [0, 2], [2, 4], // Head and ears
                [5, 7], [7, 9], // Left arm
                [6, 8], [8, 10], // Right arm
                [5, 6], // Shoulders
                [5, 11], [6, 12], // Torso sides
                [11, 12], // Hips
                [11, 13], [13, 15], // Left leg
                [12, 14], [14, 16], // Right leg
            ];

            poses.forEach((pose) => {
                pose.keypoints.forEach((keypoint) => {
                    if (keypoint.score > 0.5) {
                        ctx.beginPath();
                        ctx.arc(keypoint.x, keypoint.y, 5, 0, 2 * Math.PI);
                        ctx.fillStyle = "red";
                        ctx.fill();
                    }
                });

                keypointConnections.forEach(([startIdx, endIdx]) => {
                    const startPoint = pose.keypoints[startIdx];
                    const endPoint = pose.keypoints[endIdx];

                    if (startPoint && endPoint && startPoint.score > 0.5 && endPoint.score > 0.5) {
                        ctx.beginPath();
                        ctx.moveTo(startPoint.x, startPoint.y);
                        ctx.lineTo(endPoint.x, endPoint.y);
                        ctx.strokeStyle = "blue";
                        ctx.lineWidth = 2;
                        ctx.stroke();
                    }
                });
            });
        }

        function drawFrame() {
            ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            ctx.drawImage(videoElement, 0, 0, width, height);

            // Draw skeleton overlay
            poseStore.subscribe((poses) => {
                if (poses.length > 0) {
                    drawSkeleton(poses, ctx);
                }
            });

            // Process every `frameSkip + 1` frame
            if (frameCounter % (frameSkip + 1) === 0 && !isProcessing) {
                isProcessing = true;
                const imageData = ctx.getImageData(0, 0, width, height);
                moveNetWorker.postMessage({imageData});
            }

            frameCounter++;
            requestAnimationFrame(drawFrame);
        }

        drawFrame();
    }
</script>

<style>
    canvas {
        border: 1px solid #ccc;
    }
</style>

<div>
    <video id="videoElement" autoplay playsinline hidden></video>
    <canvas bind:this={canvasElement}></canvas>
</div>
