<script>
    import { onMount } from "svelte";
    export let onCanvasUpdate;

    let canvas;
    let fabric; // Declare fabric globally for access across functions

    // Options for brush types and configurations
    const brushes = ["PencilBrush", "CircleBrush", "SprayBrush"];
    let selectedBrush = "PencilBrush";
    let brushColor = "blue";
    let brushWidth = 50;

    onMount(async () => {
        const fabricModule = await import("fabric");
        fabric = fabricModule.fabric || fabricModule.default || fabricModule;

        // Initialize Fabric.js canvas
        canvas = new fabric.Canvas("drawingCanvas");
        canvas.isDrawingMode = true;

        console.log("Canvas initialized with drawing mode enabled.");

        // Trigger callback on path creation
        canvas.on("path:created", () => {
            if (onCanvasUpdate) {
                // Use Fabric.js's contextContainer to get the merged canvas
                const ctx = canvas.contextContainer;
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                // Debug log to verify pixel data
                if (imageData.data.some((value) => value !== 0)) {
                    console.log("ImageData extracted:", imageData);
                    onCanvasUpdate(imageData);
                } else {
                    console.warn("Canvas appears to be blank or empty.");
                }
            }
        });
    });

    // Function to set the selected brush type
    function setBrush(brushType) {
        if (!canvas || !fabric) return;

        switch (brushType) {
            case "CircleBrush":
                canvas.freeDrawingBrush = new fabric.CircleBrush(canvas);
                break;
            case "SprayBrush":
                canvas.freeDrawingBrush = new fabric.SprayBrush(canvas);
                break;
            default:
                canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        }

        // Apply brush configurations
        canvas.freeDrawingBrush.color = brushColor;
        canvas.freeDrawingBrush.width = brushWidth;

        console.log(`Brush set to ${brushType} with color ${brushColor} and width ${brushWidth}`);
    }

    // Function to clear the canvas
    function clearCanvas() {
        canvas?.clear();
        console.log("Canvas cleared.");
    }

    // Function to toggle drawing mode on/off
    function toggleDrawingMode() {
        if (canvas) {
            canvas.isDrawingMode = !canvas.isDrawingMode;
            console.log(`Drawing mode: ${canvas.isDrawingMode}`);
        }
    }
</script>

<div>
    <!-- Brush Controls -->
    <div>
        <label for="brush">Brush Type:</label>
        <select id="brush" bind:value={selectedBrush} on:change={() => setBrush(selectedBrush)}>
            {#each brushes as brush}
                <option value={brush}>{brush}</option>
            {/each}
        </select>
    </div>

    <div>
        <label for="color">Brush Color:</label>
        <input id="color" type="color" bind:value={brushColor} on:input={() => setBrush(selectedBrush)} />
    </div>

    <div>
        <label for="width">Brush Width:</label>
        <input
                id="width"
                type="number"
                min="1"
                max="50"
                bind:value={brushWidth}
                on:input={() => setBrush(selectedBrush)}
        />
    </div>

    <!-- Action Buttons -->
    <div>
        <button on:click={clearCanvas}>Clear Canvas</button>
        <button on:click={toggleDrawingMode}>
            {canvas?.isDrawingMode ? "Disable Drawing" : "Enable Drawing"}
        </button>
    </div>
</div>

<canvas id="drawingCanvas" width="800" height="600" style="border: 1px solid #ccc;"></canvas>

<style>
    div {
        margin-bottom: 10px;
    }

    label {
        margin-right: 10px;
    }

    select, input[type="color"], input[type="number"] {
        margin-right: 10px;
    }

    button {
        background-color: #282c34;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    button:hover {
        background-color: #3b3f47;
    }

    canvas {
        margin-top: 20px;
    }
</style>
